/* div:hover span {
    display: block;
} */

.image-container {
    position: relative;
    display: inline-block;
}

.image-container img {
    display: block;
    width: auto;
    /* Ensure the image scales with the container */
    height: 100px;
    /* Maintain aspect ratio */
}

.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    background-color: rgba(46, 46, 46, 0.719);
    opacity: 0;
    transition: opacity 0.9s ease;
}

.image-container:hover .overlay-text {
    opacity: 100%;
}

.overlayButton {
    color: black;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.5s ease;
}

.overlayButton:hover {
    background-color: #e4e4e4;
}

.swotBox {
    background-color: #f8fbfe;
    /* box-shadow: 0px 2px 4px #6C98AD; */
    border: none;
    border-radius: 10px;
    padding: 1px 20px 20px 20px;
    margin-left: 0.25;
    margin-right: 0.25;
    margin-top: 10px;
    margin-bottom: 2;
}