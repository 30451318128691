.app-bar {
    background-color: #163E64 !important;
    transition: width 0.3s, margin 0.3s;
}

.app-bar.open {
    margin-left: 240px;
    width: calc(100% - 240px);
}

.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.logo img {
    height: 40px;
    margin-right: 10px;
}

.logo-text {
    color: white;
    font-size: 24px;
    line-height: 40px; /* Match the height of the image */
}

.menu {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.menu li {
    display: inline;
}

.menu a {
    color: white;
    text-decoration: none;
    font-size: 16px;
}

.menu a:hover {
    text-decoration: underline;
}