/* Add styles for the selected ListItem */
.selected .MuiListItemButton-root {
  background-color: #f0f0f0;
  /* Change to your desired color */
  color: #000;
  /* Change text color if needed */
}

.delete-product {
  transition: color 0.3s ease-in, background-color 0.3s ease-in;
}

.delete-product:hover {
  color: red;
  cursor: pointer;
  background-color: transparent;
}