.loading-box {
        display: flex;
        border: 1px solid #EBEBEB;
        padding: 10px; /* Ensure padding is in pixels */
        background-color: rgb(250, 244, 190);
        min-height: 50px;
        border-radius: 5px; /* Rounded corners */
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Arial, sans-serif; /* Better font */
        font-size: 16px; /* Font size */
        color: #333; /* Text color */
        position: relative;
    }
    
   

    .error-box {
        width: 100%;
        border: 1px solid #ce0000; /* Prominent border color */
        padding: 10px; /* Ensure padding is in pixels */
        background-color: rgb(255, 235, 235); /* Light red background */
        min-height: 50px;
        border-radius: 8px; /* Rounded corners */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Arial, sans-serif; /* Better font */
        font-size: 16px; /* Font size */
        color: #333; /* Text color */
        position: relative;
    }
    
    .error-box::before {
        content: "⚠️"; /* Warning icon */
        font-size: 24px; /* Icon size */
        margin-right: 10px; /* Space between icon and text */
    }