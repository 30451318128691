.chatQuestionSendButton {
    background-color: #24272a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 5px;
    height: 40px;
    width: 40px;
}

.chatQuestionAppBar {
    bottom: 0;
    padding-bottom: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}
.chatQuestionSendButton:hover {
    background-color: #3b3f45;
    box-shadow: 0px 2px 4px #3b3f45;
}


.chatQuestionRecordButton {
    color: #24272a;
    height: 30px;
    width: 30px;
    transition: all 0.7s ease;
}


.chatQuestionRecordButton:hover {
    border-radius: 50%;
    color: white;
    background-color: #404040;
    box-shadow: 0px 2px 4px #B6B5B5;
    cursor: pointer;
}

.pauseStyle {
    font-size: 20px;
    color: #FF8200;
    transition: all 0.7s ease !important;
    animation: breathing-shadow 4s infinite;
    border-radius: 50%;
}

.pauseStyle:hover {
    color: #FF8200;
    cursor: pointer;
}

@keyframes breathing-shadow {
    0% {
        box-shadow: 0 0 0 rgba(255, 130, 0, 0.5);
    }

    50% {
        box-shadow: 0 0 20px rgba(255, 130, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 0 rgba(255, 130, 0, 0.5);
    }
}

.toolsStyle {
    font-size: 20px;
    color: #BDBDBD;
    transition: all 0.7s ease !important;
}

.toolsStyle:hover {
    color: #24272A;
    cursor: pointer;
}

.deleteIcon:hover {
    color: red;
}


.recommendation-box {
    border: 1px solid #E0E0E0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    background-color: #F8FBFE;
    cursor: pointer;
    transition: all 0.3s ease;
}

.recommendation-box:hover {
    background-color: white;
}

.markdown-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.markdown-container h3 {
    color: #333;
    margin-top: 20px;
}

.markdown-container strong {
    color: #555;
}

.markdown-container ul,
.markdown-container ol {
    padding-left: 20px;
}

.markdown-container li {
    margin-bottom: 10px;
}