body {
  background-color: #E1E5ED;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 0px;
}

/*******HEADER*******/

ul {
  overflow: hidden;
}

li {
  float: left;
}

li a {
  text-decoration: none;
  color: black;
}

li a:hover {
  color: grey;
}

.pointer-cursor {
  cursor: pointer;
}

/******BODY******/

.home {
  text-align: center;
  font-size: 50px;
  color: skyblue;
}

.container {
  border: 1px solid;
  border-radius: 5px;
  border-color: black;
  padding: 5px;
  margin: auto;
  max-width: 600px;
  background-color: lightgray;
}


input[type=text] {
  display: inline-block;
  font-size: 20px;
  margin-bottom: 5px;
}

input[type=text]:hover {
  background-color: white;
}

button {
  border: 1px solid;
  border: 5px;
  background-color: #739C9A;
  color: black;
  width: 50%;
  padding: 28px;
  transition-duration: 0.4s;
  font-size: 25px;
  display: block;
  margin: 0 auto;
}

button a {
  text-decoration: none;
  color: black;
}

button:hover {
  background-color: #739C9A;
}

label {
  font-size: 40px;
  display: inline-block;
}

.main {
  background-color: skyblue;
}

/* th {
  background-color: gray;
  color: white;
} */

.material {
  text-align: center;
  font-size: 50px;
  color: skyblue;
}

.swot-section {
  margin-top: 10px;
  margin-bottom: 10px;
}

.App-footer {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  max-height: 6vh;
  color: #a1a1a1;
  font-size: 15px;
}



/* Add SoftwareStyles */

.doneIcon {
  transition: filter 0.3s, cursor 0.3s;
  color: rgb(10, 148, 10);
}

.doneIcon:hover {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  cursor: pointer;
}

.cancelIcon {
  transition: filter 0.3s, cursor 0.3s;
  color: rgb(148, 19, 10);
}

.cancelIcon:hover {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2));
  cursor: pointer;
}

.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  ;
}

.form-control {
  width: 100%;
  max-width: 100%;
}

.main-container {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
}

.main-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  z-index: 1;
}

.main-container>* {
  position: relative;
  z-index: 2;
  /* Ensure content is above the overlay */
}

.chip-container>span {
  background-color: gray;
  padding: 1px 3px;
  border-radius: 4px;
}

.formControlRoot {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 300px;
  flex-wrap: wrap;
  flex-direction: row;
  border: 2px solid lightgray;
  padding: 4px;
  border-radius: 4px;
}

.formControlRoot>div.container {
  gap: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

