.go-home-404 {
    margin-top: 10px;
    text-decoration: none;
    background-color: #163E64;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    color: white;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.go-home-404:hover {
    background-color: #163e64bb;
}