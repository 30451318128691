body {
    background-color: #F7F7F7 !important;

}

.side-bar .MuiDrawer-paper {
    background-color: #163E64;
}

.side-bar {
    z-index: 1200;
}

.side-bar-link {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px;
}

.side-bar-link.active {
    border-left: 4px solid #ffffff;
    /* White border on the left */
    /* Optional: Change background color for active link */
    border-radius: 5px;
    background-color: #455c729c;
}